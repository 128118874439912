/* styles.css */

:root {
  /* --primary-green2: #4fbb26; */
  --primary-green2: var(--primary-green2);
  /* --primary-gradient:var(--primary-gradient) */
}

@font-face {
  font-family: "Montserrat";
  src: url("../src/assets/fonts/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  overflow-y: scroll;
  background-color: #f5faf6 !important;
  font-family: "Montserrat", sans-serif !important;
}

.popover {
  font-family: "Montserrat", sans-serif !important;
}

.nav-tabs .nav-item {
  font-family: "Montserrat", sans-serif !important;
}

.btn {
  font-family: "Montserrat", sans-serif !important;
}

h6,
.h6,
h5,
.h5,
h4,
.h4,
h3,
.h3,
h2,
.h2,
h1,
.h1 {
  font-family: "Montserrat", sans-serif !important;
}

.popover {
  --bs-popover-max-width: 500px !important;
}

.user-avatar,
[class^="user-avatar"]:not([class*="-group"]) {
  /* background: #60cf4f !important; */
  background: var(--primary-green2) !important;
  
}
* {
  box-sizing: border-box;
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: your-colors-here;
}

/* WebKit (Chrome, Safari) */
*::-webkit-scrollbar {
  width: thin;
}
.apps-menu .nk-menu-item:hover em {
  /* color: #60cf4f; */
  color: var(--primary-green2);
}

.apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  color: var(--primary-green2) !important;
}

.apps-menu .nk-menu-item.active {
  background-color: #eeffeb !important;
}

.apps-menu .nk-menu-item:hover {
  background-color: #eeffeb;
}

.dark-mode .apps-menu .nk-menu-item:hover em {
  /* color: #60cf4f !important; */
  color: var(--primary-green2) !important;
}

.dark-mode .nk-menu-icon .icon {
  color: #c3eeeb !important;
}

.dark-mode .apps-menu .nk-menu-item:hover {
  background-color: #2c3e50 !important;
}

.dark-mode .apps-menu .nk-menu-item.active .nk-menu-icon .icon {
  /* color: #60cf4f !important; */
  color: var(--primary-green2) !important;
}

.dark-mode .apps-menu .nk-menu-item.active {
  background-color: #2c3e50 !important;
}

.form-check-input:checked {
  /* background-color: #60cf4f !important; */
  background-color: var(--primary-green2) !important;
  /* border-color: #60cf4f !important; */
  border-color: var(--primary-green2) !important;
}
.form-check-input {
  background-color: #ffffff !important;
  border-color: #727272 !important;
}

.dropdown-menu-s1 {
  border-top-color: #f5faf6 !important;
}

.dark-mode .btn-dim.btn-outline-primary {
  /* background: linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%) !important; */
  color: #ffffff !important;
  /* border-color: #60cf4f !important; */
  border-color: var(--primary-green2)  !important;
}

.dark-mode .btn-dim.btn-outline-primary:hover {
  background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%) !important;
  color: #ffffff !important;
  /* border-color: #60cf4f !important; */
  border-color: var(--primary-green2)  !important;
}

.btn-dim.btn-outline-primary {
  /* background: linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%) !important; */
  color: #ffffff !important;
  /* border-color: #60cf4f !important; */
  border-color: var(--primary-green2)  !important;
}

.btn-dim.btn-outline-primary:hover {
  /* background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%) !important; */
  color: #ffffff !important;
  /* border-color: #60cf4f !important; */
  border-color: var(--primary-green2)  !important;
}

.custom-card {
  padding: 0px !important;
}

.sticky-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); Optional: add a shadow for better separation */
}

.user-dropdown-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container {
  position: absolute;
}

.user-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: 20px; /* Adjust spacing between progress and user */
}

.invoice-print {
  width: 210mm; /* A4 paper width */
  min-height: 297mm; /* A4 paper height */
  padding: 20px; /* Space around the content */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Optional shadow */
}


:root {
  --hover-bg-color-light: #baddb5;
  --hover-bg-color-dark: #3c4d3c; /* Darker green shade for dark mode */
}

.nk-notification-item {
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.nk-notification-item:hover {
  background-color: var(--hover-bg-color-light);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

/* Dark mode styles */
.dark-mode .nk-notification-item:hover {
  background-color: var(--hover-bg-color-dark);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}


/* ------------------------------------- */

#payment-form {
  width: 40vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  margin-top: auto;
  margin-bottom: auto;
}


#payment-message {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}

/* Buttons and links */
.stripebutton {
  /* background: #60cf4f; */
  /* background: linear-gradient(23deg, rgb(0 0 0) 0%, rgb(33 99 6) 35%, rgb(69 231 66) 100%) !important; */
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  /* display: block; */
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 80%;
}

.stripebutton:hover {
  filter: contrast(115%);
  /* background: linear-gradient(23deg, rgb(82 224 93) 0%, rgb(54 186 0) 35%, rgb(4 28 4) 100%) !important; */
}

.stripebutton:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  /* background: #60cf4f; */
  background: var(--primary-green2);
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  /* background: #60cf4f; */
  background: var(--primary-green2);
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

/* dynamic payment methods annotation */
#dpm-annotation {
  align-self: center;
  color: #353A44;
  width: 30vw;
  min-width: 500px;
  line-height: 20px;
  margin-bottom: 20px;
}

#dpm-integration-checker {
  display: inline;
  color: #533AFD;
}

/* Payment status page */
#payment-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  width: 30vw;
  min-width: 500px;
  min-height: 380px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
  opacity: 0;
  animation: fadeInAnimation 1s ease forwards;
  margin-top: auto;
  margin-bottom: auto;
}

#status-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* h2 {
  margin: 0;
  color: #444db6;
  text-align: center;
} */

/* #dpm-integration-checker {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: Arial, sans-serif;
  display: block;
}
#dpm-integration-checker:hover {
  filter: contrast(120%);
} */

#details-table {
  overflow-x: auto;
  width: 100%;
}

table {
  width: 100%;
  font-size: 14px;
  border-collapse: collapse;
}
table tbody tr:first-child td {
  border-top: 1px solid #E6E6E6; /* Top border */
  padding-top: 10px;
}
table tbody tr:last-child td {
  border-bottom: 1px solid #E6E6E6; /* Bottom border */
}
td {
  padding-bottom: 10px;
}

.TableContent {
  text-align: right;
  color: #6D6E78;
}

.TableLabel {
  font-weight: 600;
  color: #30313D;
}

#view-details {
  color: #0055DE;
}

#retry-button {
  text-align: center;
  background: #0055DE;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes fadeInAnimation {
  to {
      opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  #payment-form, #dpm-annotation, #payment-status {
    width: 80vw;
    min-width: initial;
  }
}

.fc-dayGridMonth-button {
  /* background-color: #60CF4F !important;  */
  background-color: var(--primary-green2) !important;
  color: white !important; 
}


.fc-timeGridWeek-button.active,
.fc-timeGridDay-button.active,
.fc-listWeek-button.active,
.fc-dayGridMonth-button.active {
  /* background-color: #60CF4F !important;  */
  background-color: var(--primary-green2) !important;
  color: white !important;
}


.fc-timeGridWeek-button,
.fc-timeGridDay-button,
.fc-listWeek-button,
.fc-dayGridMonth-button {
  background-color: #f5f6f7 !important; 
  color: #000000 !important;
}


.fc-timeGridWeek-button:hover,
.fc-timeGridDay-button:hover,
.fc-listWeek-button:hover,
.fc-dayGridMonth-button:hover {
  background-color: #ddd !important;
}





/* Container for the Plan and Price */
.plan-price-container {
  padding-bottom: 10px; /* Space at the bottom */
  display: flex; /* Flexbox for layout */
  align-items: center; /* Align items vertically */
  justify-content: flex-start; /* Align items to the left */
  font-family: Arial, sans-serif; /* Font styling */
}

/* Styling for the Plan name */
.plan-name {
  font-weight: bold; /* Bold for the plan name */
  font-size: 1.2rem; /* Slightly larger font size */
  color: #333; /* Dark color for text */
}

/* Styling for the Price */

/* Soft Green */
.plan-price {
  margin-left: 1rem; /* Space between plan name and price */
  font-size: 1.2rem; /* Standard font size */
  color: var(--primary-green2) ; /* Soft green */
  font-weight: normal; /* Regular font weight */
}

.dropdown-item {
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  /* background-color: #60CF4F !important;  */
  background-color: var(--primary-green2) !important;
  color: #FFFFFF !important;

}

.fade-message {
  color: red;
  opacity: 0;            
  transition: opacity 0.5s ease-in-out; 
}

.fade-message.visible {
  opacity: 1;          
}
